// eslint-disable-next-line 
import Sp1 from '../../assets/sp1.png';
import Sp2 from '../../assets/sp2.png';
const supportData = [
    {
        id: 1,
        title: '텔레그램 바로가기',
        imageUrl: Sp1,
        link: 'https://t.me/starsholdem'
    },
    /*
    {
        id: 2,
        title: '카카오톡 바로가기',
        imageUrl: Sp2
    }*/
]

export default supportData