// eslint-disable-next-line 

import React, { useCallback } from 'react'
import LayoutHome from '../../../components/layout-home/LayoutHome'
import supportData from '../../../data/support'
import ButtonIcon from '../../../icons/ButtonIcon';
import Styles from '../styles/Home.module.scss'
import btn_icon from "../../../assets/04_help_btn_2024-06-28/04_help_btn.png"
import "../styles/support.scss"
export default function Support() {

    const renderSupport = useCallback(() => {
        return supportData.map((support, index) => (
            <a href={support.link} key={support?.id} className="box_support">            
                <div className='flex justify-around items-center'>
                    <div className='flex flex-col justify-start items-start md:w-[320px]'>
                        <span className="support_title">
                            {support.title}
                        </span>
                        <button className="support_btn">
                            <img src={btn_icon} alt="btn" />
                        </button>
                    </div>
                    <div className="img_service">
                        <img src={support.imageUrl} alt={support.title} className='w-full h-full' />
                    </div>
                </div>                
            
            </a>
        ))
    }, []);

    return (
        <div id="section_4" className='bg-[#f3f9ff] mt-[30px]'>
            <LayoutHome
                className='flex flex-col justify-center items-center'
                title={"도움이 필요하신가요?"}
                titleStyle="support_header"
            >
                <div className='grid grid-cols-1 xl:grid-cols-1 my-[20px] mt-0 mb-[20px] sm:mb-[50px] lg:mt-[50px]  xl:!mb-[100px] xl:mt-[60px] mx-auto support_gap'>
                    {renderSupport()}
                </div>
            </LayoutHome>
        </div>
    )
}
