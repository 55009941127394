// eslint-disable-next-line 

import IconFb from "../../icons/IconFaceBook";
import IconInstagram from "../../icons/IconInstagram";
import XIcon from "../../icons/XIcon";
import FooterImg from '../../assets/footer.png';
import Logo from "../../assets/05_bottom_logo_2024-06-27/05_bottom_logo.png";
import React from "react";
import "../footer/footer.scss";
const Footer: React.FC = () => {
    return (
        <footer className={`footer_container bg-[#fffff] h-[290px] w-full `}>
            <div className="flex flex-col justify-center items-center text-center  sm:pt-[30px] md:pt-[30px] lg:pt-[50px]">
                <div className="flex justify-between items-center mb-[34px]">
                    <div className="mx-[5px]">
                        <XIcon />
                    </div>
                    <div className="mx-[5px]">
                        <IconFb />
                    </div>
                    <div className={`mx-[5px]`}>
                        <IconInstagram />
                    </div>
                </div>
                <span className="footer_content">
                    이메일: starsholdem1@gmail.com 
                </span>
                <span className='footer_content'>
                    Copyright © Sterholdem All rights reserved.
                </span>
                <img src={Logo} alt="" className="w-[110px] h-[35px] object-contain mt-[30px]" />
            </div>
        </footer>
    );
};

export default Footer;



