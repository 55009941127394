import React from "react";
import Styles from "../home/styles/Home.module.scss"
import ContentSection from "./components/ContentSection";
import Banner from "./components/Banner";
import Service from "./components/Service";
import Support from "./components/Support";
import BannerContent1 from "./components/BannerContent1";

const Home: React.FC = () => {
    return (
      <>
        <div className={`${Styles.container} container mx-auto`}>
          <ContentSection />
        </div>
        <div id="section_2_mobile" className={Styles.banner_container_res_1}>
          <Banner />
        </div>
        <div id="section_2" className={Styles.banner_container_res_2}>
          <BannerContent1 />
        </div>

        <Service />
        <Support />
      </>
    );
}

export default Home;